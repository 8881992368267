import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Popup, Marker, CircleMarker, Circle } from 'react-leaflet';
import L, {  LatLngExpression, LatLngTuple } from 'leaflet';
//import * as L from "leaflet";
import styles from './map.module.scss'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'
import 'assets/images/peak.png'


export default function Home() {
  
  const position: LatLngTuple = [35.9548, 52.1100];
  const zoom: number = 7;
  
  //.addTo(map)
  // const Esri_NatGeoWorldMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}', {
  //   attribution: 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
  //   maxZoom: 16
  // });
 

  return (
    <MapContainer center={position} zoom={zoom} scrollWheelZoom={false}>
      
      <TileLayer
        attribution = 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC'
        url = 'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}'
        

      
      />
      <Circle center = {position} radius = {100000}  color = 'red' fill = {false}>
      </Circle>
      <Circle center = {position} radius = {200000}  color = 'red' fill = {false}>
      </Circle>
      <Circle center = {position} radius = {300000}  color = 'red' fill = {false}>
      </Circle>
      <Marker position={position} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
        
        <Popup>
          Mount Damavand Peak <br />
          <center>
            <img src="https://eoimages.gsfc.nasa.gov/images/imagerecords/5000/5267/ISS010-E-13393_lrg.jpg" width="200" height="132.2"></img>
          </center> <br />
          
        </Popup>
      </Marker>

    </MapContainer>
  );
}